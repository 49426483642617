/// <reference path="../typings/index.d.ts" />

interface JQuery {
    alphanum(settings?: Object);

    alpha(settings?: Object);

    numeric(settings?: Object);

    swipe(settings?: Object);
}

interface Pace {
    on(evento: string, callback: Function): void;
}

declare var Pace: Pace;

class Tools {
    static isMobile() {
        return $(window).width() <= 1024;
    }

    static inIE9() {
        return $.browser.msie && parseInt($.browser.version, 10) > 8;
    }
}

class Modal {
    constructor() {
        this.initialize();
    }

    initialize() {
        $('.overlay #OverlayCerrar, .overlay .boton, .overlay .close-action').on('click', (evt) => {
            this.closeAll();
        });

        $('.overlay').on('click', (evt) => {
            this.closeAll();
        });

        $('.overlay .modal').on('click', (evt) => {
            evt.stopPropagation();
        });

        $(document).keyup((e) => {
            if (e.keyCode == 27 && $('body').hasClass('modal-active')) {
                this.closeAll();
            }
        });
    }

    showModal(elm: string) {
        $('.overlay .modal').removeClass('activo');
        $('.overlay .modal.' + elm).trigger('antesAbrir');
        $('.overlay').addClass('activo');
        $('.overlay .modal.' + elm).addClass('activo');
    }

    closeAll() {
        var sel = $('.overlay .modal.activo');
        $('.overlay .modal').removeClass('activo');
        $('.overlay').removeClass('activo');
        sel.trigger('despuesCerrar');
        $(".overlay_subida").removeClass("activo");
        $("#OverlayAccion").fadeOut(0);
    }
}

class Galeria {
    constructor() {
        this.userEvents();
    }

    userEvents() {
        $("ul.cuartos.grilla.galeria li").on("click", (evt) => {
            var id = $(evt.currentTarget).data("id");
            $(".overlay_galeria").addClass("activo");
            $(".overlay_galeria .contenido_gal[data-id='" + id + "']").addClass("activo");
        });

        $(".overlay_galeria .cerrar, .overlay_galeria").on("click", (evt) => {
            $(".overlay_galeria").removeClass("activo");
            $(".overlay_galeria .contenido_gal").removeClass("activo");
        });
    }
}

class Slider {
    constructor() {
        this.userEvents();
    }

    userEvents() {
        $(".slider .accion .flecha").on("click", (evt) => {
            console.log("entró");
            var muestra = parseInt(<string>$(evt.currentTarget).data("id"));
            var direccion = "";

            if ($(evt.currentTarget).hasClass("flecha_der")) {
                if (muestra == $(".slider .imagenes img").length) {
                    muestra = 1;
                } else {
                    muestra++;
                }
            }
            if ($(evt.currentTarget).hasClass("flecha_izq")) {
                if (muestra == 1) {
                    muestra = $(".slider .imagenes img").length;
                } else {
                    muestra--;
                }
            }

            this.moverSlider(muestra);
        });

        $(".thumb img").on("click", (evt) => {
            var muestra = $(evt.currentTarget).data("id");

            this.moverSlider(muestra);
        });
    }

    moverSlider(muestra) {
        for (var c = 1; c <= $(".slider .imagenes img").length; c++) {
            if (c < muestra) {
                $(".slider .imagenes img[data-id='" + c + "']").removeClass("derecha").removeClass("activo").addClass("izquierda");
            }
            if (c == muestra) {
                $(".slider .imagenes img[data-id='" + c + "']").removeClass("derecha").removeClass("izquierda").addClass("activo");
            }
            if (c > muestra) {
                $(".slider .imagenes img[data-id='" + c + "']").removeClass("izquierda").removeClass("activo").addClass("derecha");
            }
        }

        $(".thumb img").removeClass("activo");
        $(".thumb img[data-id='" + muestra + "']").addClass("activo");
        $(".slider .accion .flecha").data("id", muestra);
    }
}

class CommonController {
    static submitted;

    frm_contacto = $("#frm_contacto");
    modalAdmin: Modal;

    constructor() {
        this.userEvents();
        this.initForms();
        new Slider();
        new Galeria();

        this.cargaGeneral();
    }

    allActions() {
        //console.log("CommonController:allActions");
    }

    cargaGeneral() {
        Pace.on('hide', function () {
            $('.contenido_total').css('display', 'block');
        });
    }

    initForms() {
        $('form .numeric').numeric({});
        $('form .alphanum').alphanum();
        $('form .alpha').alpha();

        $.validator.addMethod('checkMail', (value, element) => {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(value);
        }, $.validator.messages['email']);

        this.frm_contacto.validate({
            showErrors: (errorMap, errorList) => {
                if (errorList.length >= 1 && CommonController.submitted) {
                    var summary = "Por favor, validar el(los) siguiente(s) campo(s): <br>";
                    $.each(errorList, function () {
                        summary += " * " + this.message + "<br>";
                    });
                    $("#OverlayMensaje").html(summary);
                    $("#OverlayTitulo").html("Aviso");
                    $("#Overlay").addClass("activo");

                    CommonController.submitted = false;
                }
            }
        });
    }

    userEvents() {
        this.modalAdmin = new Modal();

        $('.overlay .modal.mensajes').on('despuesCerrar', (evt) => {
            setTimeout(() => {
                $("#OverlayTitulo").html("");
                $("#OverlayMensaje").html("");
            }, 750);
        });

        $(".boton_menu, ul.menu.movil li.cerrar").on("click", (evt) => {
            $(".boton_menu").toggleClass("activo");
            //$("body").toggleClass("activo");
            $("ul.menu.movil").toggleClass("activo");
        });


        $("ul.menu.movil > li").on("click", (evt) => {
            if (!$(evt.currentTarget).children("ul.submenu.principal").hasClass("activo")) {
                $("ul.menu.movil > li > ul.submenu.principal").removeClass("activo");
                $(evt.currentTarget).children("ul.submenu.principal").addClass("activo");
            } else {
                $("ul.menu.movil > li > ul.submenu.principal").removeClass("activo");
            }
        });

        $("ul.submenu.principal > li").on("click", (evt) => {
            if ($(evt.currentTarget).parent().hasClass("activo")) {
                if (!$(evt.currentTarget).children("ul.submenu.secundario").hasClass("activo")) {
                    $(evt.currentTarget).parent().toggleClass("activo");
                    $("ul.submenu.principal > li").children("ul.submenu.secundario").removeClass("activo");
                    $(evt.currentTarget).children("ul.submenu.secundario").addClass("activo");
                } else {
                    $(evt.currentTarget).parent().toggleClass("activo");
                    $("ul.submenu.principal > li").children("ul.submenu.secundario").removeClass("activo");
                }
            } else {
                $("ul.submenu.principal > li").children("ul.submenu.secundario").removeClass("activo");
            }
        });

        $("#frm_contacto #btn_enviar").on("click", (evt) => {
            var dato = {
                txt_nombre: $("#frm_contacto #txt_nombre").val(),
                txt_correo: $("#frm_contacto #txt_correo").val(),
                txt_phone: $("#frm_contacto #txt_phone").val(),
                txt_asunto: $("#frm_contacto #txt_asunto").val(),
                txt_mensaje: $("#frm_contacto #txt_mensaje").val()
            };
            $.ajax({
                type: "POST",
                url: "Registros/Registrar.php",
                data: dato,
                dataType: 'json',
                success: function (response) {
                    if(response.status == "Correcto") {
                        $('#OverlayTitulo').html("Aviso");
                        $('#OverlayMensaje').html(response.modal);
                        $("#Overlay").addClass("activo");
                        document.getElementById('frm_contacto').reset();
                    } else {
                        $('#OverlayTitulo').html("Error");
                        $('#OverlayMensaje').html(response.modal);
                        $("#Overlay").addClass("activo");
                    }
                },
                error: function (response) {
                    var res = "Error obteniendo datos del sitio, verifique su conexión a internet.";
                    $('#OverlayMensaje').html(res);
                    $('#OverlayTitulo').html("Aviso");
                    $("#Overlay").addClass("activo");
                }
            });

        });
    }
}

var Public = {
    initiatedClasses: {}
}

var UTIL = {
    exec: function (controller, action = 'allActions') {
        if (window[controller]) {
            if (Public.initiatedClasses[controller]) {
                var controllerClass = Public.initiatedClasses[controller];
            } else {
                var controllerClass = Object.create((<any>window[controller]).prototype);
                controllerClass.constructor.apply(controllerClass);
                Public.initiatedClasses[controller] = controllerClass;
            }
            if (controllerClass[action]) {
                controllerClass[action]();
            }
        }
    },
    init: function () {
        var body = $("body");
        var controller = body.data("router-class");
        var action = body.data("router-action");


        this.exec("CommonController");
        this.exec(controller);
        this.exec(controller, action);
    }
}

$(document).on('ready', function () {
    UTIL.init();
});